<template>
  <div
    class="record-live-wapper"
  >
    <template v-if="isShowWhiteBoard">
      <white-board-replay
        v-show="!loading"
        :room-info="roomInfo"
        @handleHideLoading="handleHideLoading"
      />
    </template>

    <div
      v-if="loading"
      class="pic-loading"
    >
      <el-image
        v-show="imgUrl && !isShowLoading"
        class="head-image"
        :src="imgUrl"
        @load="imageLoad"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLiveRoom, stopRecord } from '@/services/room';

export default {
  components: {
    WhiteBoardReplay: () => import('./components/WhiteBoardReplay'),
  },
  data() {
    return {
      loading: true,
      roomInfo: {},
      isShowImage: false,
      imgUrl: '',
      isShowWhiteBoard: false,
    };
  },
  computed: {
    ...mapState('topbar', [
      'isShowLoading',
    ]),
  },
  async mounted() {
    await this.getLiveInfo();
  },

  methods: {
    async getLiveInfo() {
      try {
        const { id } = this.$route.params;
        if (id) {
          const res = await getLiveRoom(id);
          if (Object.keys(res).length) {
            this.roomInfo = res;
            this.imgUrl = res?.posterImgUrl;
            this.isShowWhiteBoard = true;
          }
        }
      } catch (err) {
        setTimeout(() => {
          this.handleStopRecord();
        }, 5000);
      }
    },
    handleHideLoading() {
      this.loading = false;
    },
    imageLoad() {
      this.$store.commit('topbar/SET_IS_SHOW_LOADING', false);
    },
    async handleStopRecord() {
      await stopRecord({
        liveRoomId: this.$route.params,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .record-live-wapper {
    margin: 0 auto;
    text-align: center;

    .head-image {
      width: 1920px;
      height: 1080px;
    }

    .pic-loading {
      color: #0c111e;

      .el-icon-loading {
        font-size: 30px;
      }
    }
  }

</style>
